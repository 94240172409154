<script setup>
import { ref, onMounted, watch, reactive } from "vue";
import Label from "@/components/Label";
import VueSelect from "vue-select";
import { api } from "@/services/api";
import { notify } from "@kyvg/vue3-notification";
import { useLoading } from "vue-loading-overlay";
import { utils } from "@/utilities";
import { Modal } from "flowbite";

import ActivityFS from "@/firebases/ActivityFS";

import { useFarmerStore } from "@/stores/farmer";
import { useVillageStore } from "@/stores/village";
import { useDealerStore } from "@/stores/dealer";
import { useHybridStore } from "@/stores/hybrid";
import { useStaffStore } from "@/stores/staff";
import { useCompetitorStore } from "@/stores/competitor";
import { useContextStore } from "@/stores/context";
import LogFS from "@/firebases/LogFS";

const farmerStore = useFarmerStore();
const villageStore = useVillageStore();
const dealerStore = useDealerStore();
const hybridStore = useHybridStore();
const staffStore = useStaffStore();
const competitorStore = useCompetitorStore();
const contextStore = useContextStore();

const emits = defineEmits(["checked"]);

const neededDealer = [
  "App\\Models\\SAMeetWithDealerSubDealer",
  "App\\Models\\SASpotSale",
];
const neededVillage = [
  "App\\Models\\SAFieldDay",
  "App\\Models\\SAFocusVillage",
  "App\\Models\\SAFarmerMeeting",
  "App\\Models\\SAContact",
  "App\\Models\\SACaravan",
];
const neededFarmer = ["App\\Models\\SAHarvest"];
const needOther = ["App\\Models\\SAOtherCommercial"];

let dealersOptions = [];
let villageOptions = [];
let farmersOptions = [];
let hybridsOptions = [];
let staffsOptions = [];
let competitorsOptions = [];

const form = reactive({
  activity_type: "",
  schedule_date: utils.getCurrentDateTime(),
  model: "",
  remark: "",
  schedulable_id: ""
});

const $loading = useLoading({});
const modal = ref(null);

onMounted(async () => {
  await farmerStore.initFarmers();
  farmersOptions = farmerStore.getFarmers();

  if (farmersOptions.length == 0 || contextStore.getIsOffline == false) {
    farmersOptions = await api.getFarmers();
    farmersOptions.forEach((data) => {
      farmerStore.addNewFarmer(data);
    });
  }

  await villageStore.initVillages();
  villageOptions = villageStore.getVillages();

  if (villageOptions.length == 0 || contextStore.getIsOffline == false) {
    villageOptions = await api.getVillages();
    villageOptions.forEach((data) => {
      villageStore.addNewVillage(data);
    });
  }

  await dealerStore.initDealers();
  dealersOptions = dealerStore.getDealers();

  if (dealersOptions.length == 0 || contextStore.getIsOffline == false) {
    dealersOptions = await api.getDealers();
    dealersOptions.forEach((data) => {
      dealerStore.addNewDealer(data);
    });
  }

  await hybridStore.initHybrids();
  hybridsOptions = hybridStore.getHybrids();

  if (hybridsOptions.length == 0 || contextStore.getIsOffline == false) {
    hybridsOptions = await api.getHybrids();
    hybridsOptions.forEach((data) => {
      hybridStore.addNewHybrid(data);
    });
  }

  await staffStore.initStaffs();
  staffsOptions = staffStore.getStaffs();

  if (staffsOptions.length == 0 || contextStore.getIsOffline == false) {
    staffsOptions = await api.getStaffs();
    staffsOptions.forEach((data) => {
      staffStore.addNewStaff(data);
    });
  }

  await competitorStore.initCompetitors();
  competitorsOptions = competitorStore.getCompetitors();

  if (competitorsOptions.length == 0 || contextStore.getIsOffline == false) {
    competitorsOptions = await api.getCompetitors();
    competitorsOptions.forEach((data) => {
      competitorStore.addNewCompetitor(data);
    });
  }

  farmersOptions = await utils.convertToSelectObject(farmersOptions);
  villageOptions = await utils.convertToSelectObject(villageOptions);
  dealersOptions = await utils.convertToSelectObject(dealersOptions);
  hybridsOptions = await utils.convertToSelectObject(hybridsOptions);

  const options = {
    placement: "bottom-right",
    backdrop: "dynamic",
    backdropClasses: "bg-gray-900/50 dark:bg-gray-900/80 fixed inset-0 z-40",
    closable: false
  }

  // instance options object
  const instanceOptions = {
    id: 'un-plan-modal',
    override: true,
  };
  const $targetEl = document.getElementById('un-plan-modal');

  modal.value = new Modal($targetEl, options, instanceOptions);
});

watch(
  () => form.activity_type,
  (newV) => {
    form.model = [];
  }
);

const showModal = () => {
  modal.value.show();
};


const closeModal = () => {
  modal.value.hide();
};

const clearForm = () => {
  form.activity_type = "";
  form.model = "";
  form.remark = "";
  form.schedulable_id = "";
}

const addUnplannedActivity = async () => {
  const loader = $loading.show({});

  if (
    form.model == "" &&
    form.activity_type != "App\\Models\\SAOtherCommercial"
  ) {
    notify({
      group: "bottom-center-group",
      type: "error",
      title: "Dealer/Village/Farmer is required",
      text: "Required",
    });

    loader.hide();
    return;
  }

  if (contextStore.getIsOffline) {
    form.schedulable_id = form.model?.value || "";
    form.id = utils.generateUUID();
    form.order_sorted = new Date().getTime();
    ActivityFS.createOrUpdateActivity(form);

    setTimeout(() => {
      loader.hide();
      emits("checked", true);
      clearForm();
      closeModal();
      return;
    }, 1000);
  } else {
    try {
      const result = await api.addUnPlanActivity(form);
      if (result.status) {
        
        setTimeout(() => {
          loader.hide();
          emits("checked", true);
          clearForm();
          closeModal();
          return;
        }, 1000);
      } else {
        const error = result.data;
        for (const [key, value] of Object.entries(error)) {
          notify({
            group: "bottom-center-group",
            type: "error",
            title: "There was an error!",
            text: value + " [ " + key + " ]",
          });

          loader.hide();
          return;
        }

        console.error("There was an error!", error);
        loader.hide();
      }
    } catch (error) {
      notify({
        group: "bottom-center-group",
        type: "error",
        title: "There was an error!",
        text: "contact administrator !",
      });

      loader.hide();
      LogFS.createLog(error);
      console.error("There was an error!", error);
    }
  }
};
</script>
<template>
  <!-- Modal toggle -->
  <button
    data-modal-target="un-plan-modal"
    data-modal-toggle="un-plan-modal"
    type="button"
    class="w-1/2 block disabled:cursor-not-allowed py-4 text-base bg-gray-100 text-black border-2 border-gray-400 rounded-lg mx-auto"
    @click.prevent="showModal"
  >
    <span class="text-center mx-auto">Add Unplanned Activity</span>
  </button>

  <div
    id="un-plan-modal"
    tabindex="-1"
    aria-hidden="true"
    class="my-modal hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full"
  >
    <div class="relative p-4 w-full max-w-md max-h-full">
      <!-- Modal content -->
      <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
        <!-- Modal header -->
        <div
          class="flex items-center justify-between p-4 md:p-5 border-b rounded-t"
        >
          <h3 class="text-xl font-semibold text-gray-900">
            Add Unplanned Activity
          </h3>
          <button
            type="button"
            class="end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center"
            data-modal-hide="un-plan-modal"
            @click.prevent="closeModal()"
          >
            <svg
              class="w-3 h-3"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 14 14"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
              />
            </svg>
            <span class="sr-only">Close modal</span>
          </button>
        </div>
        <!-- Modal body -->
        <div class="p-4 md:p-5">
          <form class="space-y-4" action="#" @submit.prevent="addUnplannedActivity">
            <div>
              <Label
                class="block mb-2 text-sm font-medium text-gray-900 required"
                value="Activity Type"
              />
              <select class="w-full rounded-lg" v-model="form.activity_type" required>
                <option value="">----- Choose Activity Type -----</option>
                <option value="App\Models\SAMeetWithDealerSubDealer">
                  Dealer/Sub Meeting
                </option>
                <option value="App\Models\SAFieldDay">Field Day</option>
                <option value="App\Models\SASpotSale">Spot Sale</option>
                <option value="App\Models\SAFocusVillage">Corn Tour</option>
                <option value="App\Models\SAFarmerMeeting">
                  Farmer Meeting
                </option>
                <option value="App\Models\SAContact">Contact</option>
                <option value="App\Models\SACaravan">Caravan</option>
                <option value="App\Models\SAHarvest">Harvest</option>
                <option value="App\Models\SAOtherCommercial">Others</option>
              </select>
            </div>
            <div>
              <Label
                class="block mb-2 text-sm font-medium text-gray-900 required"
                value="Schedule Date"
              />
              <input
                type="date"
                :value="utils.getCurrentDate()"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                disabled
              />
            </div>
            <div class="py-2" v-if="neededDealer.includes(form.activity_type)">
              <Label
                class="block mb-2 text-sm font-medium text-gray-900 required"
                value="Dealer/Sub Dealer"
              />
              <VueSelect
                filterable
                :options="dealersOptions"
                label="label"
                v-model="form.model"
                placeholder="Select"
                :searchable="true"
                class="block w-full"
                required
              />
            </div>
            <div class="py-2" v-if="neededFarmer.includes(form.activity_type)">
              <Label
                class="block mb-2 text-sm font-medium text-gray-900 required"
                value="Farmer"
              />
              <VueSelect
                filterable
                :options="farmersOptions"
                label="label"
                v-model="form.model"
                placeholder="Select"
                :searchable="true"
                class="block w-full"
                required
              />
            </div>
            <div class="py-2" v-if="neededVillage.includes(form.activity_type)">
              <Label
                class="block mb-2 text-sm font-medium text-gray-900 required"
                value="Village"
              />
              <VueSelect
                filterable
                :options="villageOptions"
                label="label"
                v-model="form.model"
                placeholder="Select"
                :searchable="true"
                class="block w-full"
                required
              />
            </div>
            <div class="py-2">
              <Label
                class="block mb-2 text-sm font-medium text-gray-900"
                value="Remark"
              />
              <textarea
                class="mt-0 block w-full rounded-lg resize-y"
                rows="3"
                v-model="form.remark"
              />
            </div>

            <div class="flex justify-end">
              <button
                type="submit"
                class="inline-flex items-center px-4 py-2 border border-transparent rounded-lg font-semibold text-xs tracking-widest hover:bg-gray-700 active:bg-gray-900 focus:outline-none focus:border-gray-900 focus:ring focus:ring-gray-300 disabled:opacity-25 transition ml-2 bg-green-500 text-white"
              >
                Add
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
